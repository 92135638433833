.pricing {
    /* max-width: 1150px; */
    margin: 0 auto;
}

.pricing-table {
    display: flex;
    justify-content: center;
}

.pricing-table .ant-list-item {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 21px;
}

.pricing-saving {
    background-color: #e5fde4;
    color: #0cb130;
    border-radius: 21px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    padding: 3px 10px;
    text-transform: uppercase;
    font-weight: 600;
}

.current-plan {
    background-color: #d3d3d336;
}

.pricing_grid {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 20px;
    margin: 30px 0;
}

.pricing_grid_item {
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
    padding-bottom: 24px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 7px 11px -4px rgba(0,23,62,0.2), 0 0 1px 0 #a8b9d5;
}

.pricing_grid_item.pricing_grid_item_recommended {
    border: 2px solid #2693ff!important;
    background: linear-gradient(180deg,rgba(38,147,255,.05),hsla(0,0%,100%,0)),#fff;
    margin-top: -16px;
    margin-bottom: -16px;
    /* padding-top: 49px; */
    padding-bottom: 38px;
    z-index: 1;
    border-radius: 8px;
}

.pricing_grid_item.pricing_grid_item_current {
    border: 1px solid lightgrey !important;
}

.pricing_header {
    padding: 32px 24px;
    font-size: 14px;
    text-align: center;
    border-radius: 3px 3px 0 0;
}

.pricing_grid_item.pricing_grid_item_recommended .pricing_header {
    border-radius: 6px 6px 0 0;
    /* padding: 40px 24px; */
}

.pricing_price {
    padding: 24px 24px 0;
    text-align: center;
}

.pricing_buy {
    margin: 0 0 10px 0;
}

.pricing_buy .core-optin-cta {
    padding: 10px 20px;
}

.plan_header_container {
    margin: 20px 0;
}

.pricing_features {
    padding: 0 24px 14px;
}

.pricing_feature {
    justify-content: start !important;
    min-height: 48px;
    margin: 2px 0;
    padding: 0;
    border-bottom: none;
}

.pricing_feature_text {
    border-bottom: 1px dashed #d0d7e9;
    cursor: help;
    margin-left: 10px;
    text-align: left;
}

.pricing_recommended {
    font-style: normal;
    font-weight: 600;
    position: relative;
    top: -2px;
    display: inline;
    padding: 4px 8px;
    margin: 0 0 0 8px;
    font-size: 12px;
    line-height: normal;
    letter-spacing: .4px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 3px;
    box-shadow: 0 7px 11px -4px rgba(0,23,62,.2), 0 0 1px 0 #a8b9d5;
    position: absolute;
    top: -12px;
    right: 20px;
    background-color: #2693ff
}

.pricing_grid .ant-list-split .ant-list-item {
    border: none;
}

@media (max-width: 992px) {
    .pricing_grid {
        grid-template-columns: 1fr !important;
    }
    
    .pricing_grid_item {
        grid-template-columns: 1fr !important;
    }
    
    .pricing_feature {
        /* justify-content: center !important; */
    }
}

@media (max-width: 1286px) {
    .pricing_grid {
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row dense;
        justify-items: center;
    }

    .pricing_features .ant-spin-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }
}

.pricing__switcher__tabs {
    text-align: center;
    border: 2px solid #f0f2f5;
    display: inline-block;
    margin: 20px auto;
    border-radius: 3px;
}

.pricing__switcher__select {
    margin: 32px auto 20px auto;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    display: block;
}

.pricing__switcher__tab {
    display: inline-block;
    background-color: #f0f2f5;
    font-size: 15px;
    font-weight: 600;
    color:  #2e2e2ecf;
    padding: 5px 25px;
    cursor: pointer;
}

.pricing__switcher__tab.active .type:before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 5px;
    background-color: #19a611;
    margin-right: 9px;
    vertical-align: top;
    margin-top: 9px;
    display: inline-block
}

.pricing__switcher__tab:last-child {
    margin-left: -4px;
}

.pricing__switcher__tab.active {
    background-color: white;
    color:  #000000;
}