@import '~antd/dist/antd.css';

h5 {
  font-family: 'Open Sans';
  font-size: 20px;
  line-height: 1.3;
  color: #333b69;
  font-weight: 700 !important;
  margin-top: 0;
  margin-bottom: .5em;
}

.widget-form {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  text-align: center;
}

.widget-form .widget-card {
  width: 800px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 25px 50px -10px rgba(0,0,0,.1);
  margin-bottom: 20px;
  padding: 20px;
}

.button-action {
  padding: 10px 17px;
  text-align: center;
  font-weight: 700;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  border: 0;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  color: #fff;
  backface-visibility: hidden;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: linear-gradient(90deg,#1ba005 0,#0ec851) !important;
  /* background: linear-gradient(to right, #f8875f 0%, #fa6262 100%); */
}

.button-action:hover {
  color: white !important;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.link-ceator {
  display: flex;
  width: 100%;
}

.link-ceator > div {
  width: 100%;
}



.core-optin-modal {
  top: 10px !important;
  text-align: center;
}

/* .core-optin-modal .ant-modal-body div {
   font-size: 17px;
   padding: 10px 0;
} */

.core-optin-modal .ant-modal-header,
.core-optin-modal .ant-modal-close {
   display: none !important;
}

.core-optin-modal .ant-modal-body {
   border-top: 5px solid #ffbe02;
}

.core-optin-modal .ant-modal-content {
   border-radius: 0;
}

.core-optin-cta {
   position: relative !important;
   background-image: linear-gradient(90deg,#1ba005 0,#0ec851) !important;
   line-height: 46px !important;
   height: 100% !important;
   min-width: 160px !important;
   color: #fff!important;
   box-shadow: 0 2px 4px 0 rgba(27,160,5,.2) !important;
   font-weight: 700 !important;
   font-size: 15px !important;
   border: none !important;
   margin-bottom: 10px;
}

.demo-optin-cta {
   position: relative !important;
   background-image: linear-gradient(90deg,#1ba005 0,#0ec851) !important;
   line-height: 42px !important;
   height: 100% !important;
   min-width: 160px !important;
   color: #fff!important;
   box-shadow: 0 2px 4px 0 rgba(27,160,5,.2) !important;
   font-weight: 700 !important;
   font-size: 14px !important;
   border: none !important;
   margin-bottom: 10px;
}

.has-error .ant-input-prefix,
.has-error .ant-form-explain, 
.has-error .ant-form-split {
    color: darkorange !important;
}

.has-error .ant-input-affix-wrapper .ant-input, .has-error .ant-input-affix-wrapper .ant-input:hover,
.has-error .ant-input-group-addon {
    /* color: darkorange !important; */
    border-color: darkorange !important;
}

.has-error .ant-calendar-picker-icon::after, 
.has-error .ant-time-picker-icon::after, 
.has-error .ant-picker-icon::after, 
.has-error .ant-select-arrow, 
.has-error .ant-cascader-picker-arrow,
.has-error .ant-input-group-addon {
    color: darkorange !important;
}

.earnings-calculator-input-data {
  display: flex;
  justify-content: center;
}

.earnings-calculator-traffic {
  margin-right: 25px;
}

.earnings-calculator-traffic > div {
  margin: 10px 0;
}

.earnings-calculator-traffic > div > span {
  min-width: 250px;
  display: inline-block;
}

.earnings-calculator-table {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin: 35px auto 0 auto;
}

.site-manager-flag {
  height: 11px !important;
  margin-right: 5px !important;
  vertical-align: initial !important;
}
